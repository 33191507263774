import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppManager, AppManagerContext } from './manager';
import { ModalContainer } from './comps/modal';
import { NotifyContainer } from './comps/notify';
import { DtiFactory } from './api';
import { Dtiapp_initer } from './gen/dti/appconsole/initer';

async function bootstrap() {

  let resp  = await fetch('/api/v5/appconsole/initer');
  let conf:Dtiapp_initer.Resu = await resp.json();



  const app = new AppManager(conf);

  ReactDOM.render(
    <React.StrictMode>
      <AppManagerContext.Provider value={app}>
        <App />
        <ModalContainer manager={app.modalManager} />
        <NotifyContainer store={app.notifyStore} />
      </AppManagerContext.Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 
}

reportWebVitals(console.log);
bootstrap();