import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAsync } from "src/comm/hook.aync";
import { Loader } from "src/comps/loader";
import { Dtiapp_appList } from "src/gen/dti/appconsole/app.list";
import { Dtiapp_appGet } from "src/gen/dti/appconsole/app.get";
import { useAppManager } from "src/manager";
import { ErrorViewer } from "src/comps/error";
import { PageLay } from "../page.lay";
import { AppEdit } from "./edit";
import { AppKey } from "./key";
import { AppSettings } from "./settings";
import { AppSampleTool } from "./sample";
import { AppDelete } from "./delete";
import { AppDash } from "./dash";
import { AppAccess } from "./access";
import {

  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import { Datetime } from "src/comps/datetime";
import { PagePubliclay } from "../page.lay.pub";
export function PageAppView() {
  const { apiAppconsole } = useAppManager();
  const { app } = useParams<{ app: string }>();
  const r = useRouteMatch()
  // const [uapp, setUapp] = useState<Dtiapp_appGet.Resu>()



  const uapp = useAsync(async () => {
    return await apiAppconsole.dti(Dtiapp_appGet.meta).call({ q: { id: app } });
  }, true);

  useEffect(() => {
    uapp.handle();
  }, [app]);

  if (uapp.error) {
    return <PagePubliclay >
      <section className="section">

        <div className="container">
          <ErrorViewer error={uapp.error} />
          <Link to='/list' >go app list</Link>
        </div>

      </section>

    </PagePubliclay>
  }

  if (uapp.loading) {
    return <PageLay title={`...`} >
      <section className="section">

        <div className="container">
          <Loader box />
        </div>

      </section>

    </PageLay>
  }

  return <PageLay title={`${uapp.result?.name}`} >
    <section className="section">
      {/* <h1 className="title">App view : {app} - {uapp.result?.id}</h1> */}


      {/* {loader.loading
        ? <Loader />
        : null
      } */}


      <div className="columns">
        <div className="column is-4">
          <div className="box">
            <div className="field">
              <label className="label ">App name</label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input className="input" readOnly defaultValue={uapp.result?.name} />
                </div>
                <div className="control">
                  <Link className="button is-warning" to={`${r.url}/edit`}><i className="fas fa-cog" /></Link>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label ">App ID</label>

              <div className="field has-addons">

                <div className="control is-expanded has-icons-left">
                  <input className="input" readOnly defaultValue={uapp.result?.id} />
                  <span className="icon  is-left">
                    <i className="fas fa-cube"></i>
                  </span>
                </div>
                <div className="control">
                  <a className="button">
                    <i className="fas fa-copy" />
                  </a>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label ">App domain</label>

              <div className="field has-addons">

                <div className="control is-expanded has-icons-left">
                  <input className="input" readOnly defaultValue={uapp.result?.domain} />
                  <span className="icon  is-left">
                    <i className="fas fa-wifi"></i>
                  </span>
                </div>
                <div className="control">
                  <a className="button">
                    <i className="fas fa-copy" />
                  </a>
                </div>
              </div>
            </div>



            <div className="field">
              <label className="label ">Created</label>
              <div className="control">
                <div className="input" {...{ disabled: true }} ><Datetime at={uapp.result?.created_at || null} /></div>
              </div>
            </div>

            <div className="field">
              <label className="label ">Owner</label>
              <div className="control">
                <div className="input" {...{ disabled: true }} >{uapp.result?.ownerName}</div>
              </div>
            </div>


          </div>



        </div>
        {uapp.result
          ? <div className="column">
            <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
              <ul>
                <li><Link to="/list">App list</Link></li>
                <li><Link to={r.url}>{uapp.result?.name}</Link></li>

                <Route path={`${r.url}/edit`} render={() => <li className="is-active"><a href="#">edit</a></li>} />
                <Route path={`${r.url}/key`} render={() => <li className="is-active"><a href="#">keys</a></li>} />
                <Route path={`${r.url}/settings`} render={() => <li className="is-active"><a href="#">Тохируулга</a></li>} />
                <Route path={`${r.url}/sample`} render={() => <li className="is-active"><a href="#">Sample tool</a></li>} />
                <Route path={`${r.url}/delete`} render={() => <li className="is-active"><a href="#">delete</a></li>} />
                <Route path={`${r.url}/access`} render={() => <li className="is-active"><a href="#">Admins</a></li>} />

              </ul>
            </nav>

            <Route exact path={`${r.url}`} render={() => <AppDash uapp={uapp.result as any} />} />
            <Route path={`${r.url}/access`} render={() => <AppAccess uapp={uapp.result as any} />} />
            <Route path={`${r.url}/edit`} render={() => <AppEdit appid={uapp.result?.id || ''} onChange={() => uapp.handle()} />} />
            <Route path={`${r.url}/key`} render={() => <AppKey appid={uapp.result?.id || ''}  />} />
            <Route path={`${r.url}/settings`} render={() => <AppSettings appid={uapp.result?.id || ''}  />} />
            <Route path={`${r.url}/sample`} render={() => <AppSampleTool appid={uapp.result?.id || ''}  />} />
            <Route path={`${r.url}/delete`} render={() => <AppDelete uapp={uapp.result} />} />
          </div>
          : null
        }

      </div>







    </section>
  </PageLay>
}