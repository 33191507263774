import { PropsWithChildren } from "react";

interface IProps {
    error: any;

    mode?: 'text'
}


export function ErrorViewer({ error, mode, children }: PropsWithChildren<IProps>) {
    if (error) {
        if (mode === 'text') {
            return <article className="message is-danger">
                <div className="message-body">
                    <h4 className="title is-4">{error.name || 'Error'}</h4>
                    {error.message || '$$noErrorMessage$$'}
                    {children
                        ? { children }
                        : null
                    }
                </div>
            </article>
        }

        return <article className="message is-danger">
            <div className="message-header">
                <p>{error.name || 'Error'} </p>
                {/* <button className="delete" aria-label="delete"></button> */}
            </div>
            <div className="message-body">
                {error.message || '$$noErrorMessage$$'}
                {children
                    ? { children }
                    : null
                }
            </div>
        </article>
    }
    return null;
}

