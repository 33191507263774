interface IProps {
    at:Date | string | null;
}
export function Datetime ({at}:IProps){
    if(at instanceof Date) {
        return <span>{at.toLocaleString()}</span>
    }

    if(typeof at === 'string') {
        return <span>{new Date(at).toLocaleString()} </span>
    }

    if(typeof at === 'number') {
        return <span>{new Date(at).toLocaleString()}</span>
    }

    if(at) {
        return <span>{'' + at}</span>
    }

    return <span />
}