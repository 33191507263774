import { Dti } from "@napp/dti-core";

export namespace Dtiapp_appCreate {



    export interface Body {
        domain: string;
        name: string;
        
        desc : string
    }


    export interface Resu {
        id: string;
    }


    export const meta = Dti.define<Resu, void, Body>({
        name: 'app-create', method : 'post',
        checkB: (b) => {
            if ((b.domain || '') === '') {
                throw new Error('domain is requared')
            }

            if(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/.test(b.domain) === false) {
                throw new Error('format domain property')
            }

            if ((b.name || '') === '') {
                throw new Error('name is requared')
            }

        }
    })
}