import { useEffect, useRef, useState } from "react";
import Cookies from 'universal-cookie';
export function useCoockie() {

    const un = useRef(new Cookies());

    return {
        get: (name: string) => {
            return un.current.get(name) || '';
        },
        set: (name: string, value: string) => {
            un.current.set(name, value, { path: '/' });
        },
        remove: (name: string) => {
            un.current.remove(name, { path: '/' });
        }
    }
}