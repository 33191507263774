import { Dti } from "@napp/dti-core";

export namespace Dtiapp_goMemberConsole {
    export interface QParam {
        appid: string;
    }

    export interface Rsu {
        url: string;
    }


    export const meta = Dti.define<Rsu, QParam, void>({
        name: 'go-member-console',
        checkQ: (q) => {
            if ((q.appid || '') === '') {
                throw new Error('id is requared')
            }
        }
    });
}