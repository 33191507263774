import { Dti } from "@napp/dti-core";

export namespace Dtiapp_appGet {
    export interface QParam {
        id:string;
    }

    
    export interface Resu {
        id: string;
        domain: string;
        owner: string;
        ownerName: string;
        name: string;
        desc: string;
        created_at: Date
    }


    export const meta = Dti.define<Resu, QParam, void>({
        name: 'app-get'
    });
}