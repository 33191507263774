import { PropsWithChildren } from "react";
import { PageHead, IHeadProps } from "./page.head";


export function PageLay(props: PropsWithChildren<IHeadProps>) {

    return <>
        <PageHead {...props} />
        {props.children}
    </>
}