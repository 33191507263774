import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { useAppManager } from "src/manager";

export interface IHeadProps {
    title?: string;
    footer?: React.ReactNode
}
export function PageHead({ title, footer }: IHeadProps) {
    let app = useAppManager();

    return <section className="hero is-link">

        <div className="hero-body">
            <div className="container">
                <div className="is-pulled-right">
                    <div className="buttons is-hidden-mobile">
                        <a className="button is-link" target="_blank" href="/api/v5/common/go-userlyhome" >{app.session?.appusername}</a>
                        <Link className="button is-link" to="/logout">Logout</Link>
                    </div>
                    <div className="buttons is-hidden-tablet">

                        <div className="dropdown  is-right  is-hoverable">
                            <div className="dropdown-trigger">
                                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                                    <span className="icon is-small">
                                        <i className="fas fa-bars" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                <div className="dropdown-content">
                                    <div className="dropdown-item">
                                        {app.session?.appusername}
                                    </div>
                                    <Link className="dropdown-item" to="/logout">Logout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="title">
                    {title || 'Userly'}
                </p>
                <p className="subtitle">
                    удирдалгын систем
                </p>
            </div>
        </div>
        {footer
            ? <div className="hero-foot">
                <div className="container">
                    {footer}
                </div>
            </div>
            : null
        }
    </section>
}