import { Dti } from "@napp/dti-core";


export namespace Dtiapp_appKey {

    export interface Where {
        id: string
    }

    export interface Payload {
        pubkey: string

        origins: string[];
    }


    export interface Resu {
        flag: boolean;
    }


    export const metaPut = Dti.define<Resu, Where, Payload>({
        name: 'app-update-key', method: 'post',
        checkB: (b) => {
            if ((b.pubkey || '') === '') {
                throw new Error('name is pubkey')
            }
        },
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    })

    export const metaGet = Dti.define<Payload, Where, void>({
        name: 'app-update-key', method: 'get',      
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    })
}