import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "src/comm/hook.form";
import { InputText } from "src/comps/form/input.text";
import { Dtiapp_appKey } from "src/gen/dti/appconsole/app.key";
import { DtiCommon_sampleKey } from "src/gen/dti/comm/sample.key";
import { DtiCommon_genApptoken } from "src/gen/dti/comm/gen.apptoken";
import { useAppManager } from "src/manager";
import { Link } from "react-router-dom";
import { AppEditTab } from "./edit.tab";
import { InputString } from "src/comps/form/input.string";

interface IProps {
    appid: string;
}
export function AppSampleTool({ appid }: IProps) {
    const { apiAppconsole, apiCommon, notifyManager } = useAppManager();
    const history = useHistory();

    const [dtoGen, setDtoGen] = useState({
        pubkey: '',
        prikey: ''
    });

    const [dtoToken, setDtoToken] = useState({
        appdomain: '',
        expired: 60,
        payload: {
            x: ''
        } as any,
        privatekey: '',

        result: {
            expired: 0,
            token: ''
        }
    });

    const gen = async () => {
        try {
            let { pubkey, prikey } = await apiCommon.dti(DtiCommon_sampleKey.meta).call({

            });
            setDtoGen({ pubkey, prikey })
        } catch (error) {
            notifyManager.exception(error)
        }

    }

    const genApptoken = async () => {
        try {
            let { expired, token } = await apiCommon.dti(DtiCommon_genApptoken.meta).call({
                b: {
                    appdomain: dtoToken.appdomain,
                    expired: dtoToken.expired,
                    payload: dtoToken.payload,
                    privatekey: dtoToken.privatekey
                }
            });
            setDtoToken({ ...dtoToken, result: { expired, token } })
        } catch (error) {
            notifyManager.exception(error)
        }

    }

    return <div >
        <div className="box">
            <AppEditTab appid={appid} active="sample" />
        </div>


        <div className="box">
            <button className="button is-primary block" onClick={gen}>
                <span> Key gen </span>
            </button>
            <form >
                <InputText rows={6} label="Public key" value={dtoGen.pubkey} readonly />
                <InputText rows={6} label="Private key" value={dtoGen.prikey} readonly />
            </form>
        </div>
        <form className="box" onSubmit={e=>{
            e.preventDefault();
            genApptoken();
        }}>
            <div className="columns">
                <div className="column is-6">
                    <button className="button is-primary block" type="submit"  >
                        <span> gen appToken </span>
                    </button>

                    <InputString label="Appdomain" value={dtoToken.appdomain} onChange={e => setDtoToken({ ...dtoToken, appdomain: e })} required />
                    <InputText label="expired" value={'' + dtoToken.expired} onChange={e => setDtoToken({ ...dtoToken, expired: parseInt(e) })} required />
                    <InputText rows={6} label="payload" value={JSON.stringify(dtoToken.payload)} onChange={e => setDtoToken({ ...dtoToken, payload: JSON.parse(e) })} />
                    <InputText rows={6} label="private key" value={dtoToken.privatekey} onChange={e => setDtoToken({ ...dtoToken, privatekey: e })} required />
                </div>
                <div className="column is-6">
                    
                        <InputString label="token expire" value={''+dtoToken.result.expired} readonly />
                        <InputText rows={6} label="App token" value={dtoToken.result.token} readonly />
                    
                </div>
            </div>

        </form>

    </div>
}