import { useState } from "react";
import { useForm } from "src/comm/hook.form";
import { Dtiapp_appGet } from "src/gen/dti/appconsole/app.get";
import { Dtiapp_goMemberConsole } from "src/gen/dti/appconsole/go.member.console";
import { useAppManager } from "src/manager";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

interface IProps {
    uapp: Dtiapp_appGet.Resu;
}
export function AppDashAdmins({ uapp }: IProps) {
    const { modalManager, apiAppconsole, notifyManager } = useAppManager();

    const openMember = async () => {
        let {url} = await apiAppconsole.dti(Dtiapp_goMemberConsole.meta).call({
            q: {appid : uapp?.id || ''}
        });

        window.open(url, '_blank')
    }

    return <div className="card">
        <header className="card-header">
            <p className="card-header-title">
                <span className="icon "><i className="fas fa-users" /></span>
                <span> Admins</span>
            </p>

        </header>
        <div className="card-content">
            <div className="content">

            </div>
        </div>
        <footer className="card-footer">
            <Link className="card-footer-item" to={`/app/${uapp.id}/access`}>
                <span className="icon"><i className="fas fa-cog" /></span>
                <span>Тохируулга</span>
            </Link>
        </footer>
    </div>
}