import { useEffect, useRef, useState } from "react";
import { useForm } from "src/comm/hook.form";
import { Dtiapp_appGet } from "src/gen/dti/appconsole/app.get";
import { Dtiapp_accessList } from "src/gen/dti/appconsole/access.list";
import { Dtiapp_accessInvite } from "src/gen/dti/appconsole/access.invite";
import { Dtiapp_accessDelete } from "src/gen/dti/appconsole/access.delete";
import { Dtiapp_accessChange } from "src/gen/dti/appconsole/access.change";
import { ROLEAppconsole, ROLEAppconsoleDesc, ROLEAppconsoleList } from "src/gen/comm/role.appconsole";
import { useAppManager } from "src/manager";
import { useHistory } from "react-router";
import { Datetime } from "src/comps/datetime";
import { Empty } from "src/comps/empty";
import { Loader } from "src/comps/loader";
import { Modaler, ModelCardComponent } from "src/comps/modal";
import { InputString } from "src/comps/form/input.string";

interface IProps {
    uapp: Dtiapp_appGet.Resu;
}
export function AppAccess({ uapp }: IProps) {
    const { modalManager, apiAppconsole, notifyManager } = useAppManager();

    const [items, setItems] = useState<Dtiapp_accessList.Item[]>();
    const his = useHistory();

    const invite = () => {
        let mdl = modalManager.show((mdl) => <AccessInvite mdl={mdl} uapp={uapp} />);

        mdl.OnClose.subscribe(({ result }) => {
            if (result === 'ok') {
                load();
            }
        })
    }

    const edit = (it: Dtiapp_accessList.Item) => {
        let mdl = modalManager.show((mdl) => <AccessEdit mdl={mdl} uapp={uapp} item={it} />);

        mdl.OnClose.subscribe(({ result }) => {
            if (result === 'ok') {
                load();
            }
        })
    }

    const load = async () => {
        setItems(undefined)
        let { items } = await apiAppconsole.dti(Dtiapp_accessList.meta).call({ q: { appid: uapp.id } });

        setItems(items)
    }

    const del = async (it: Dtiapp_accessList.Item) => {
        let ok = await modalManager.confirm(<div>{it.appusername} Delete ??</div>);

        if (ok) {
            try {
                await apiAppconsole.dti(Dtiapp_accessDelete.meta).call({ b: { accessid: it.accessid } });
                notifyManager.info("Амжилттай");
                load();
            } catch (error) {
                notifyManager.exception(error);
            }
        }
        
    }

    useEffect(() => {
        load();
    }, [])
    return <div className="box">
        {/* <article className="message is-danger">
            <div className="message-header">
                <p><i className="fas fa-info-circle"></i> Анхааруулга</p>
            </div>
            <div className="message-body">
                <div> Устсан мэдээллийг буцаан сэргээх болмжгүй</div>
                <span><b>{uapp?.name}</b> холбоотой бүх мэдээлэл устах болно.</span>
            </div>
        </article> */}

        <div className="block">
            <button className="button is-primary" onClick={invite}>
                <span className="icon"><i className="fas fa-plus" /></span>
                <span>Хэрэглэгч урих</span>
            </button>
        </div>

        <table className="table is-bordered is-fullwidth is-striped">

            <thead>
                <tr>
                    <th>Хэрэглэгч</th>
                    <th>Огноо</th>
                    <th style={{ width: '1.5rem' }}></th>
                </tr>
            </thead>
            <tbody>
                {items === undefined
                    ? <tr>
                        <td colSpan={3}>
                            <Loader box />
                        </td>
                    </tr>
                    : null
                }
                {items?.length === 0
                    ? <tr>
                        <td colSpan={3}>
                            <Empty box />
                        </td>
                    </tr>
                    : null
                }
                {items?.map((it) => {
                    return <tr key={it.accessid}>
                        <td>
                            <div><a onClick={() => edit(it)}><i className="fas fa-edit" /></a> {it.appusername}</div>
                            <div>{it.roles.join('; ')}</div>
                        </td>
                        <td>
                            <Datetime at={it.created_at} />
                        </td>
                        <td>
                            <a onClick={() => del(it)}><i className="fas fa-trash has-text-danger" /></a>
                        </td>
                    </tr>
                })}

            </tbody>
        </table>


    </div>
}


function AccessInvite({ uapp, mdl }: IProps & { mdl: Modaler }) {
    const { apiAppconsole, notifyManager } = useAppManager();

    const [dto, setDto] = useState<Dtiapp_accessInvite.BParam>({
        identity: '',
        appid: uapp.id,
        roles: []
    });

    const ref = useRef<HTMLFormElement>(null as any)



    const frm = useForm({

        submit: async () => {
            try {
                await apiAppconsole.dti(Dtiapp_accessInvite.meta).call({
                    b: { ...dto },
                });

                notifyManager.info("Амжилттай");
                mdl.close({ result: 'ok' })
            } catch (error) {
                notifyManager.exception(error)

            }

        }
    });

    const handleRole = (role: ROLEAppconsole, checked: boolean) => {
        if (checked) {
            setDto({ ...dto, roles: [...dto.roles, role] })
        } else {
            setDto({ ...dto, roles: dto.roles.filter(it => it === role ? false : true) })
        }
    }

    return <ModelCardComponent header="Хэрэглэгч урих" mdl={mdl} footer={<button className="button is-link" onClick={() => ref.current.requestSubmit()} disabled={frm.loading}>Урих</button>}>
        <form onSubmit={frm.submiter} ref={ref} >
            <InputString label="Утас, имайл" required value={dto.identity} onChange={e => setDto({ ...dto, identity: e })} disabled={frm.loading} />
            <div className="filed">
                <label className="label">Эрх (Role)</label>
                <div className="panel" style={{ border: '1px solid #dbdbdb', borderRadius: '4px' }}>
                    {ROLEAppconsoleList.map((it, i) => {

                        return <label className="panel-block" style={{ display: 'block' }} key={it}>
                            <input type="checkbox" checked={dto.roles.find(r => r === it) ? true : false}
                                onChange={(e) => handleRole(it, e.target.checked)} disabled={frm.loading} />
                            <span className="">{it}</span> <br />
                            <span>{ROLEAppconsoleDesc[it as 'accessDelete']}</span>
                        </label>
                    })}

                </div>
            </div>
        </form>
    </ModelCardComponent>
}

function AccessEdit({ uapp, mdl, item }: IProps & { mdl: Modaler, item: Dtiapp_accessList.Item }) {
    const { apiAppconsole, notifyManager } = useAppManager();

    const [dto, setDto] = useState<Dtiapp_accessChange.BParam>({
        accessid: item.accessid,
        roles: [...item.roles]
    });

    const ref = useRef<HTMLFormElement>(null as any)



    const frm = useForm({

        submit: async () => {
            try {
                await apiAppconsole.dti(Dtiapp_accessChange.meta).call({
                    b: { ...dto },
                });

                notifyManager.info("Амжилттай");
                mdl.close({ result: 'ok' })
            } catch (error) {
                notifyManager.exception(error)

            }

        }
    });

    const handleRole = (role: ROLEAppconsole, checked: boolean) => {
        if (checked) {
            setDto({ ...dto, roles: [...dto.roles, role] })
        } else {
            setDto({ ...dto, roles: dto.roles.filter(it => it === role ? false : true) })
        }
    }

    return <ModelCardComponent header={<div>Хэрэглэгч засах</div>} mdl={mdl} footer={<button className="button is-link" onClick={() => ref.current.requestSubmit()} disabled={frm.loading}>Хадгал</button>}>
        <form onSubmit={frm.submiter} ref={ref} >
            <InputString label="Хэрэглэгч" required value={item.appusername} disabled={true} />
            <div className="filed">
                <label className="label">Эрх (Role)</label>
                <div className="panel" style={{ border: '1px solid #dbdbdb', borderRadius: '4px' }}>
                    {ROLEAppconsoleList.map((it, i) => {

                        return <label className="panel-block" style={{ display: 'block' }} key={it}>
                            <input type="checkbox" checked={dto.roles.find(r => r === it) ? true : false}
                                onChange={(e) => handleRole(it, e.target.checked)} disabled={frm.loading} />
                            <span className="">{it}</span> <br />
                            <span>{ROLEAppconsoleDesc[it as 'accessDelete']}</span>
                        </label>
                    })}

                </div>
            </div>
        </form>
    </ModelCardComponent>
}