import "./style.scss";

interface IProps {
    box?: boolean;

    height?: number | string;
}

export function Empty({ box, height }: IProps) {

    const msg = <span>
        <i className="fas fa-database"></i>
        <span> Мэдээлэл байхгүй байна</span>
    </span>;


    if (box) {
        return <div className="empty-is-box " style={{ height }}>
            {msg}
        </div>
    }

    return <span className="empty-is-inline">{msg}</span>
}