import { Dti } from "@napp/dti-core";


export namespace Dtiapp_appUpdate {

    export interface Where {
        id: string
    }

    export interface Body {
        name: string;

        desc: string
    }


    export interface Resu {
        flag: boolean;
    }


    export const meta = Dti.define<Resu, Where, Body>({
        name: 'app-update', method: 'post',
        checkB: (b) => {
            if ((b.name || '') === '') {
                throw new Error('name is requared')
            }
        },
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    })
}