import { Dti } from "@napp/dti-core";

export namespace Dtiapp_accessList {
    export interface QParam {
        appid: string;
    }

    export interface Item {
        accessid: string;
        appusername: string;

        roles: string[];
        created_at: Date
    }


    export interface Resu {
        items: Item[];
    }


    export const meta = Dti.define<Resu, QParam, void>({
        name: 'app-access-list'
    });
}