import React from 'react';
import { Subject } from 'rxjs';

export interface ICloser {
    result: 'ok' | 'no' | 'yes' | 'cancel';
    reson?: string;
}

export class Modaler {


    OnClose = new Subject<ICloser>();

    constructor(public id: string, public render: (mdl: Modaler) => React.ReactElement) {

    }


    close(closer: ICloser) {
        this.OnClose.next(closer);
    }
}


