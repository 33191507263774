import { Dti } from "@napp/dti-core";

export namespace Dtiapp_appDelete {
    export interface QParam {
        id:string;
    }

    
    export interface Resu {
        flag:boolean
    }


    export const meta = Dti.define<Resu, QParam, void>({
        name: 'app-delete',        
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    });
}