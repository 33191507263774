import { ReactNode, PropsWithChildren } from "react";

interface IProps {
    label: string;

    name?: string;

    className?: string;
    value?: string;
    onChange?: (val: string) => void;

    required?: ReactNode;
    maxLength?: number;
    minLength?: number;

    placeholder?: string;

    rows?: number;

    readonly?: boolean;
    disabled?: boolean;
    loading?: boolean;

    size?: 'small' | 'medium' | 'large';
    status?: 'success' | 'warning'

}
export function InputText({ className, label, name, onChange, value, required, minLength, maxLength, placeholder, rows, disabled, readonly, loading, size, status, children }: PropsWithChildren<IProps>) {
    return <div className={`field ${className}`}>
        <label className="label">{label} {required ? (typeof required === 'boolean' ? '*' : required) : ''}</label>
        <div className={`control ${loading ? 'is-loading' : ''} ${size ? 'is-' + size : ''}`}>
            <textarea className={`textarea ${status ? 'is-' + status : ''}`} name={name} required={required ? true : undefined} readOnly={readonly}
                minLength={minLength} maxLength={maxLength} placeholder={placeholder} disabled={disabled || loading} rows={rows}
                value={value} onChange={e => onChange && onChange(e.target.value)} />
        </div>
        {children}
    </div>
}