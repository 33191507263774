import React, { useContext } from "react";
import { Subject } from "rxjs";
import { DtiFactory } from "./api";
import { ModalManager } from "./comps/modal";
import { NotifyManager, NotifyStore } from "./comps/notify";
import { UserlyAuthentication } from "@userly.mn/auth-sdk";
import { Dtiapp_initer } from "./gen/dti/appconsole/initer";

export interface ISession {
    appusername: string;
    roles: string[];
    before_login_at?: Date;
}
export class AppManager {

    constructor(private conf:Dtiapp_initer.Resu){
       
    }

    session: ISession | null = null;

    OnSessionChange = new Subject<void>();

    setSession(sess: ISession) {
        this.session = sess;
        this.OnSessionChange.next();
    }

    delSession() {
        this.session = null;
        this.OnSessionChange.next();
    }

    get isAuthenticated() {
        if (this.session) {
            return true;
        }
        return false;
    }

    // userly = new LoginByUserlyname({ appdomain: 'console', baseUrl: 'https://userly.v5/api/v5/auth' });
    
    // userly = new LoginByUserlyname({ appdomain: 'console', baseUrl: 'https://www.userly.v5:3010/api/v5/auth' });
    userly = new UserlyAuthentication({ appdomain: 'console', userlyDomain: this.conf.userlyDomain });
    modalManager = new ModalManager();

    notifyStore = new NotifyStore();
    notifyManager = new NotifyManager(this.notifyStore);



    apiAppconsole = DtiFactory('/api/v5/appconsole', async () => {
        let udata = await this.userly.currentLogin()
        if (udata) {
            return udata.appusertoken[0]
        }

        return '';
    });

    apiCommon = DtiFactory('/api/v5/common', async () => {
        let udata = await this.userly.currentLogin()
        if (udata) {
            return udata.appusertoken[0]
        }

        return '';
    });
    
}


export const AppManagerContext = React.createContext<AppManager | null>(null);

export const useAppManager = () => {
    let c = useContext(AppManagerContext);
    if (c === null) {
        throw new Error('not defined app manager')
    }
    return c;
};