import { Link } from "react-router-dom";

export function AppEditTab(p: { appid: string, active: 'edit' | 'key' | 'settings' | 'sample' }) {


    return <div className="tabs">
        <ul>
            <li className={p.active === 'edit' ? 'is-active' : ''}>
                <Link to={`/app/${p.appid}/edit`} >
                    <span>Үндсэн</span>
                </Link>
            </li>
            <li className={p.active === 'settings' ? 'is-active' : ''}>
                <Link to={`/app/${p.appid}/settings`} >
                    <span>Тохируулга</span>
                </Link>
            </li>
            <li className={p.active === 'key' ? 'is-active' : ''}>
                <Link to={`/app/${p.appid}/key`} >
                    <span>Түлхүүр</span>
                </Link>
            </li>
            <li className={p.active === 'sample' ? 'is-active' : ''}>
                <Link to={`/app/${p.appid}/sample`} >
                    <span>Sample tool</span>
                </Link>
            </li>
            
        </ul>
    </div>

}