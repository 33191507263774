import { ReactNode, PropsWithChildren, useState, useEffect } from "react";
import { InputSelectBasic } from "../input.select.besic";

interface IProps {
    label: string;

    icon?: string;
    name?: string;

    className?: string;

    value?: boolean;
    onChange?: (val: boolean) => void;

    required?: ReactNode;


    placeholder?: string;


    disabled?: boolean;
    loading?: boolean;

    size?: 'small' | 'medium' | 'large';
    status?: 'success' | 'warning'

}

interface Item {
    id:string
    val:boolean
}

export function InputYesNo({ className, label, icon, name, onChange, value, required, placeholder, disabled, loading, size, status, children }: PropsWithChildren<IProps>) {

    const source:Item[]=[
        {id:'ok', val : true},
        {id:'no', val : false},
    ]

    return <InputSelectBasic<Item>
        label={label}
        className={className}
        val2key={(v) => v.id}
        val2string={(v) => v.val ? 'Тийм' : 'Үгүй'}
        placeholder={placeholder}
        source={source}
        icon={icon}
        name={name}
        onChange={(e)=>{
            if(onChange) {
                onChange(e.val)
            }
        }}        
        value={source.filter(it=>it.val === value).pop() }
        required={required}
        disabled={disabled}
        loading={loading}
        size={size}
        status={status}
    >
        {children}
    </InputSelectBasic>
}