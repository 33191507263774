import React from "react";
import { Subject } from "rxjs";
import { uuid } from "src/comm/uuid";

import { ICloser, Modaler } from "./modaler";
import { ModelCardComponent } from "./viewer";

export class ModalManager {
    private _items = new Array<Modaler>();

    get items() {
        return this._items;
    }

    OnChange = new Subject<void>();

    private remove(id: string) {

        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].id === id) {
                this.items.splice(i, 1);
                this.OnChange.next();
                break;
            }
        }
    }

    show(render: (mdl: Modaler) => React.ReactElement) {
        let id = uuid();
        let mdl = new Modaler(id, render);

        let un = mdl.OnClose.subscribe(() => {
            setTimeout(() => {
                un.unsubscribe();
                mdl.OnClose.complete();
                mdl.OnClose.unsubscribe();
            }, 10);
            this.remove(id);
        })

        this._items.push(mdl);
        this.OnChange.next();

        return mdl;
    }



    info(body: React.ReactElement) {
        return this.show((mdl) => <ModelCardComponent mdl={mdl} footer={<button className={`button is-success`} onClick={() => mdl.close({ result: 'ok' })}>OK</button>}>
            {body}
        </ModelCardComponent>)
    }

    confirm(body: React.ReactNode, opt?: IConfirmOptions) {

        let okTxt = opt?.okButton?.text || 'Тийм';
        let okCss = opt?.okButton?.cssStyle || 'is-success';
        let noTxt = opt?.cancelButton?.text || 'Үгүй';
        let noCss = opt?.cancelButton?.cssStyle || '';

        let mdl = this.show((mdl) => <ModelCardComponent mdl={mdl} footer={<>
            <button className={`button ${okCss}`} onClick={() => mdl.close({ result: 'ok' })}>{okTxt}</button>
            <button className={`button ${noCss}`} onClick={() => mdl.close({ result: 'cancel' })}>{noTxt}</button>
        </>}>
            {body}
        </ModelCardComponent>);

        return new Promise<boolean>((resolve) => {
            mdl.OnClose.subscribe(({ result }) => {
                process.nextTick(() => {
                    resolve(result === 'ok');
                })
            })
        })

    }

}

export interface IConfirmOptions {
    okButton?: {
        text?: string;
        cssStyle?: string;
    }
    cancelButton?: {
        text?: string;
        cssStyle?: string;
    }
}