import { useEffect, useRef, useState } from "react";

export function useForm(param: { load?: () => Promise<void>, submit?: () => Promise<void>, loadEffect?: any[] }) {
    const [loading, setLoading] = useState(false);
    const un = useRef(false);

    const submiter = (e: any) => {
        e.preventDefault();

        if (param.submit) {
            setLoading(true)
            param.submit()
                .then(() => {
                    if (un.current === false) {
                        setLoading(false)
                    }
                })
                .catch(() => {
                    if (un.current === false) {
                        setLoading(false)
                    }
                })
        }
    }

    useEffect(() => {
        if (param.load) {
            setLoading(true)
            param.load()
                .then(() => {
                    if (un.current === false) {
                        setLoading(false)
                    }

                })
                .catch(() => {
                    if (un.current === false) {
                        setLoading(false)
                    }
                })
        }
        return () => {
            un.current = true;
        }
    }, param.loadEffect || [])

    return {
        loading,
        submiter
    }
}