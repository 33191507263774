import { Dti } from "@napp/dti-core";

export namespace Dtiapp_accessChange {
    export interface BParam {
        accessid: string;
        roles: string[]
    }



    export interface Resu {
        flag: boolean
    }


    export const meta = Dti.define<Resu, void, BParam>({
        name: 'app-access-change', method: 'post'
    });
}