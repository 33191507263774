import { ReactNode, PropsWithChildren, useState, useEffect } from "react";

interface IProps<T> {
    label: string;
    val2key: (v: T) => string;
    val2string: (v: T) => string;
    icon?: string;
    name?: string;

    className?: string;
    source?: Array<T>;
    value?: T;
    onChange?: (val: T) => void;

    required?: ReactNode;

    allowNull?: string;
    placeholder?: string;


    disabled?: boolean;
    loading?: boolean;

    size?: 'small' | 'medium' | 'large';
    status?: 'success' | 'warning'

}
export function InputSelectBasic<T>({ className, label, icon, name, onChange, source, value, required, val2key, val2string, allowNull, placeholder, disabled, loading, size, status, children }: PropsWithChildren<IProps<T>>) {
    const [values, setValues] = useState<Array<T>>();
    const changeVal = (e: string) => {
        if (onChange && values) {
            for (let it of values) {
                if (e === val2key(it)) {
                    return onChange(it);
                }
            }
        }
    }

    useEffect(() => {
        setValues(source);
    }, [source]);

    return <div className={`field ${className ?? ''}`}>
        <label className="label">{label} {required ? (typeof required === 'boolean' ? '*' : required) : ''}</label>
        <div className={`control ${icon ? 'has-icons-left' : ''}  `}>
            <div className={`select is-fullwidth ${loading ? 'is-loading' : ''} ${size ? 'is-' + size : ''} ${status ? 'is-' + status : ''}`}>
                <select name={name} required={required ? true : undefined} disabled={disabled || loading}
                    value={value ? val2key(value) : undefined} onChange={e => changeVal(e.target.value)} >
                    {placeholder && value === undefined
                        ? <option value="" disabled selected hidden>{placeholder}</option>
                        : null
                    }
                    {allowNull
                        ? <option value="" >{allowNull || 'Бүгд'}</option>
                        : null
                    }

                    {values && values.map(it => {
                        let v = val2key(it);
                        return <option key={'k-' + v} value={v}>{val2string(it)}</option>
                    })}
                </select>
            </div>
            {icon
                ? <span className="icon is-small is-left">
                    <i className={`fas fa-${icon}`}></i>
                </span>
                : null
            }
        </div>
        {children}
    </div>
}