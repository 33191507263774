import { Dti } from "@napp/dti-core";


export namespace DtiCommon_genApptoken {


    export interface Body {
        payload: {
            x?: string;
        },
        appdomain: string,
        privatekey: string,
        expired: number
    }

    export interface Resu {
        token: string;
        expired: number;
    }


    export const meta = Dti.define<Resu, void, Body >({
        name: 'gen-apptoken', method : 'post'
    })
}