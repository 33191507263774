import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppManager } from "src/manager";

import { PagePubliclay } from "../page.lay.pub";
import { UserlyObject } from "@userly.mn/auth-sdk";
import { UserlyLoginForm } from "@userly.mn/auth-lform-bulma";


export function PageLogin() {
    const his = useHistory();
    const app = useAppManager();


    useEffect(() => {
        if (app.isAuthenticated) {
            his.push('/')
        }
    }, []);

    const login = (userobj:UserlyObject)=>{
        app.setSession({
            appusername: userobj.displayname,
            roles: userobj.roles,
            before_login_at: userobj.before_login_at
        });

        his.push('/')
    }

    return <PagePubliclay >

        <section className="section">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-8">
                        <UserlyLoginForm userly={app.userly} onLogin={login}   />
                    </div>
                </div>
            </div>
        </section>

    </PagePubliclay>
}

