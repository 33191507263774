import { useState } from "react";
import { Link } from "react-router-dom";
import { useAsync } from "src/comm/hook.aync";
import { Loader } from "src/comps/loader";
import { Dtiapp_appList } from "src/gen/dti/appconsole/app.list";
import { useAppManager } from "src/manager";
import { PageLay } from "src/pages/page.lay";

export function PageAppList() {
  const { apiAppconsole } = useAppManager();
  const [qparam, setQparam] = useState<Dtiapp_appList.QParam>({ search: '' });
  
  const loader = useAsync(async () => {
    try {
      let { items } = await apiAppconsole.dti(Dtiapp_appList.meta).call({ q: qparam });
      return items;  
    } catch (error) {
      
    }
    return [];
    
  });



  return <PageLay title="App list" >
    <section className="section">
      {loader.loading
        ? <Loader />
        : null
      }


      <div className="box">
        <div className="field is-grouped">
          <div className="control is-expanded">
            <input className="input" type="text" placeholder="Хайх ..." value={qparam.search} onChange={e => setQparam({ ...qparam, search: e.target.value })} />
          </div>
          <div className="control">
            <button className="button is-info" onClick={loader.handle}>
              <span>Хайх</span>
            </button>
          </div>
        </div>
      </div>

      <div className="columns is-multiline">
        <div className="column is-6 is-4-desctop is-2-widescreen">
          <div className="box">
            <Link to="/create" >
              <span className="icon"><i className="fas fa-plus" /></span>
              <span>Шинэ апп</span>
            </Link>
          </div>
        </div>
        {loader.result?.map((it) => {
          return <div className="column is-6 is-4-desctop is-2-widescreen" key={it.id}>
            <div className="box">
              <Link to={`/app/${it.id}`} >
                {it.name}
              </Link>
            </div>
          </div>

        })}


      </div>



    </section>
  </PageLay>
}