import { useState } from "react";
import { InputString } from "src/comps/form/input.string";
import { InputText } from "src/comps/form/input.text";
import { useForm } from "src/comm/hook.form";
import { useAppManager } from "src/manager";
import { Dtiapp_appCreate } from "src/gen/dti/appconsole/app.create";
import { useHistory } from "react-router";
import { PageLay } from "../page.lay";

export function PageAppCreate() {

  const { apiAppconsole, notifyManager } = useAppManager();
  const history = useHistory();

  const [dto, setDto] = useState({
    name: '', domain: '', desc: ''
  });

  const frm = useForm({
    submit: async () => {
      try {

        let { id } = await apiAppconsole.dti(Dtiapp_appCreate.meta).call({
          b: { ...dto }
        });

        notifyManager.info("App create success");

        history.push('/list')
      } catch (error) {
        notifyManager.exception(error)
      }

    }
  });

  return <PageLay title="App create" >
    <section className="section">
      <div className="box">
        <h1 className="title">App create</h1>
        <div className="columns">
          <div className="column is-4">
            <form onSubmit={frm.submiter} >
              <InputString label="Апп нэр" value={dto.name} onChange={e => setDto({ ...dto, name: e })} disabled={frm.loading} />
              <InputString label="Domain" value={dto.domain} onChange={e => setDto({ ...dto, domain: e })} disabled={frm.loading} />
              <InputText label="desc" value={dto.desc} onChange={e => setDto({ ...dto, desc: e })} disabled={frm.loading} />

              <div className="buttons">
                <button type="button" className="button" disabled={frm.loading} onClick={() => history.push('/list')}>
                  <span>Cancel</span>
                </button>
                <button type="submit" className="button is-primary" disabled={frm.loading}>
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
  </PageLay>
}