import { Dti } from "@napp/dti-core";


export namespace DtiCommon_sampleKey {



    export interface Resu {
        pubkey: string;
        prikey: string;
    }


    export const meta = Dti.define<Resu, void, void>({
        name: 'sample-key'
    })
}