import { Dti } from "@napp/dti-core";

export namespace Dtiapp_accessDelete {
    export interface BParam {
        accessid: string;
    }

    export interface Resu {
        flag:boolean
    }

    export const meta = Dti.define<Resu, void, BParam>({
        name: 'app-access-delete', method: 'post'
    });
}