import { PropsWithChildren } from "react";
export function PagePubliclay(props: PropsWithChildren<{}>) {
    return <>
        <section className="hero is-primary">

            <div className="hero-body">
                <div className="container">
                    <p className="title">
                        Userly
                    </p>
                    <p className="subtitle">
                        удирдалгын систем
                    </p>
                </div>
            </div>
        </section>
        {props.children}
        <section style={{height : '150px'}}></section>
        <footer className="footer fixed-bottom py-4">
            <div className="content has-text-centered">
                <p className="mb-0">
                    <strong>Userly</strong> удирдалгын систем. <br />
                    &copy; 2013-2021. API Solution LLC.
                </p>
            </div>
        </footer> 
    </>
}