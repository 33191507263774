import { Dti } from "@napp/dti-core";

export namespace Dtiapp_appList {
    export interface QParam {
        search?: string;
    }

    export interface Item {
        id: string;
        name: string;

        created_at: Date
    }


    export interface Resu {
        items: Item[];
    }


    export const meta = Dti.define<Resu, QParam, void>({
        name: 'app-list'
    });
}