import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "src/comm/hook.form";
import { InputText } from "src/comps/form/input.text";
import { Dtiapp_appKey } from "src/gen/dti/appconsole/app.key";
import { useAppManager } from "src/manager";
import { Link } from "react-router-dom";
import { AppEditTab } from "./edit.tab";

interface IProps {
    appid: string;
}
export function AppKey({ appid }: IProps) {
    const { apiAppconsole, notifyManager } = useAppManager();
    const history = useHistory();

    const [dto, setDto] = useState({
        pubkey: '',
        origins: [] as string[]
    });

    useEffect(() => {
        if (appid) {
            (async () => {
                let { pubkey,origins } = await apiAppconsole.dti(Dtiapp_appKey.metaGet).call({
                    q: { id: appid }
                });
                setDto({ pubkey, origins })
            })();
        }
        return () => { }
    }, [appid])

    const frm = useForm({

        submit: async () => {
            try {
                // console.log('dto', dto)
                let { flag } = await apiAppconsole.dti(Dtiapp_appKey.metaPut).call({
                    b: { pubkey: dto.pubkey, origins : dto.origins },
                    q: { id: appid }
                });

                if (flag) {
                    // onChange()
                }

                notifyManager.info("App update success");


            } catch (error) {
                notifyManager.exception(error)
            }

        }
    });
    return <div className="box">
        <AppEditTab appid={appid} active="key" />
        <form onSubmit={frm.submiter} >
            
            <InputText rows={6} label="RSA Public key" value={dto.pubkey} onChange={e => setDto({ ...dto, pubkey: e })} disabled={frm.loading} />

            <div className="columns">
                <div className="column">
                    <div className="buttons">
                       
                        <button type="submit" className="button is-primary" disabled={frm.loading}>
                            <span>Submit</span>
                        </button>

                        <button type="button" className="button" disabled={frm.loading} onClick={() => history.push(`/app/${appid}`)}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
               
            </div>

        </form>

    </div>
}