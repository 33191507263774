import { useState } from "react";
import { useForm } from "src/comm/hook.form";
import { Dtiapp_appGet } from "src/gen/dti/appconsole/app.get";
import { Dtiapp_appDelete } from "src/gen/dti/appconsole/app.delete";
import { useAppManager } from "src/manager";
import { useHistory } from "react-router";

interface IProps {
    uapp?: Dtiapp_appGet.Resu;
}
export function AppDelete({ uapp }: IProps) {
    const { modalManager, apiAppconsole, notifyManager } = useAppManager();
    const [appName, setAppName] = useState('');
    const his = useHistory();
    const frm = useForm({
        submit: async () => {
            if (appName && uapp) {
                let a1 = appName.trim();
                let a2 = (uapp.name || '').trim();

                if (a1 && a2 && a1 === a2) {
                    await apiAppconsole.dti(Dtiapp_appDelete.meta).call({ q: { id: uapp.id } });
                    notifyManager.success('app delete success');
                    his.push('/list')
                } else {
                    modalManager.info(<div>Устгах апп нэр тохирохгүй байна.</div>)
                }
            }

        }
    })

    return <form onSubmit={frm.submiter} className="box">
        <article className="message is-danger">
            <div className="message-header">
                <p><i className="fas fa-info-circle"></i> Анхааруулга</p>
            </div>
            <div className="message-body">
                <div> Устсан мэдээллийг буцаан сэргээх болмжгүй</div>
                <span><b>{uapp?.name}</b> холбоотой бүх мэдээлэл устах болно.</span>
            </div>
        </article>

        <div className="field">
            <label className="label">Устгах Appname</label>
            <p className="control has-icons-left ">
                <input className="input" name="name" required disabled={frm.loading}
                    value={appName} onChange={e => setAppName(e.target.value)}
                />
                <span className="icon is-small is-left"><i className="fas fa-bookmark"></i></span>
            </p>
        </div>
        <div className="field">
            <p className="control">
                <button className="button is-danger" disabled={frm.loading}>
                    <span>Delete</span>
                </button>
            </p>
        </div>
    </form>
}