import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "src/comps/loader";
import { useAppManager } from "src/manager";
import { useCoockie } from "src/comm/hook.cookie";


export function PageLogout() {
  const app = useAppManager();
  const his = useHistory();
  const cookie = useCoockie();


  useEffect(() => {
    app.userly.logout()
      .then(() => {
        app.delSession();

        his.push('/')
      })
      .catch(e => console.log(e))

  }, [])

  return <section className="section">



    <Loader box />






  </section>
}