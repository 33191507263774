import { Dti } from "@napp/dti-core";


export namespace Dtiapp_appSettings {

    export interface Where {
        id: string
    }

    export interface Payload {
        public_register: boolean;
        terms: string;
        terms_url: string;
        terms_text: string;

        policy: string;
        policy_url: string;
        policy_text: string;
    }


    export interface Resu {
        flag: boolean;
    }


    export const metaUpdate = Dti.define<Resu, Where, Payload>({
        name: 'app-settings-update', method: 'post',
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    })

    export const metaGet = Dti.define<Payload, Where, void>({
        name: 'app-settings-get', method: 'get',
        checkQ: (b) => {
            if ((b.id || '') === '') {
                throw new Error('id is requared')
            }
        }
    })
}