import { AppManager, useAppManager } from './manager';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { useEffect, useState } from 'react';
// import { RoutePrivate } from './comps/route.private';
// import { Page404 } from './pages/404';
// import { PageLogin, PageLogout } from './pages/login';
// import { PageDashboard } from './pages/dashboard';
// import { PageSiteContent } from './pages/site/content';

// import { useEffect, useState } from 'react';

import { PageAppList } from './pages/app.list';
import { PageAppCreate } from './pages/app.create';
import { PageAppView } from './pages/app.view';
import { PageLogin } from './pages/login';
import { PageLogout } from './pages/logout';

import { Loader } from './comps/loader';
import { RoutePrivate } from './comps/route.private';




export function App() {
  let app = useAppManager();
  // let cookie = useCoockie();
  const [check, setCheck] = useState(true);
  const [logined, setLogined] = useState(app.isAuthenticated);
  const checkLogin = async () => {

    try {

      let udata = await app.userly.currentLogin();
      if (udata) {
        app.setSession({        
          appusername: udata.displayname,
          roles: udata.roles,
          before_login_at: udata.before_login_at
        });
      }



    } catch (error) {
      // app.notifyManager.exception(error);
    }
    finally {
      setCheck(false)
    }

  }


  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    let un = app.OnSessionChange.subscribe(() => {
      setLogined(app.isAuthenticated)
    });

    return () => un.unsubscribe();
  }, [])

  if (check) {
    return <Loader color="green" box />
  }

  return <Router >
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/list" />} />
      <Route path="/login" component={PageLogin} />      
      <Route path="/logout" component={PageLogout} />      
      <RoutePrivate isAuthenticated={logined} path="/list" component={PageAppList} />
      <RoutePrivate isAuthenticated={logined} path="/create" component={PageAppCreate} />
      <RoutePrivate isAuthenticated={logined} path="/app/:app" component={PageAppView} />
      {/* <RoutePrivate isAuthenticated={logined} path="/dashboard" component={PageDashboard} />
  <RoutePrivate isAuthenticated={logined} path="/site/content" component={PageSiteContent} />
  <RoutePrivate isAuthenticated={logined} path="/site/settings" component={PageSiteSettings} />
  <RoutePrivate isAuthenticated={logined} path="/content-category" component={PageContentCategory} />
  <RoutePrivate isAuthenticated={logined} path="/member/list" component={PageMemberProfile} />
  <RoutePrivate isAuthenticated={logined} path="/member-category" component={PageMemberCategory} />
  <RoutePrivate isAuthenticated={logined} path="/member-request" component={PageMemberRequest} />
  <RoutePrivate isAuthenticated={logined} path="/report" component={PageReport} /> */}
      {/* <Route component={Page404} /> */}
    </Switch>
  </Router>
}