import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "src/comm/hook.form";
import { Dtiapp_appSettings } from "src/gen/dti/appconsole/app.settings";
import { useAppManager } from "src/manager";
import { Link } from "react-router-dom";
import { AppEditTab } from "./edit.tab";
import { InputYesNo } from "src/comps/form/input.yesno";
import { InputText } from "src/comps/form/input.text";
import { InputString } from "src/comps/form/input.string";

interface IProps {
    appid: string;    
}
export function AppSettings({ appid }: IProps) {
    const { apiAppconsole, notifyManager,modalManager } = useAppManager();
    const history = useHistory();

    const [dto, setDto] = useState({
        policy: '',
        policy_text: '',
        policy_url: '',
        public_register: false,
        terms: '',
        terms_text: '',
        terms_url: ''
    });

    useEffect(() => {
        if (appid) {
            (async () => {
                try {
                    let { policy, policy_text, policy_url, public_register, terms, terms_text, terms_url } = await apiAppconsole.dti(Dtiapp_appSettings.metaGet).call({
                        q: { id: appid }
                    });
                    setDto({ policy, policy_text, policy_url, public_register, terms, terms_text, terms_url })    
                } catch (error) {
                    notifyManager.exception(error);
                }
                
            })();
        }
        return () => { }
    }, [appid])

    const frm = useForm({

        submit: async () => {
            try {
                // console.log('dto', dto)
                await apiAppconsole.dti(Dtiapp_appSettings.metaUpdate).call({
                    b: {
                        public_register: dto.public_register,

                        policy: dto.policy,
                        policy_text: dto.policy_text,
                        policy_url: dto.policy_url,
                        
                        terms: dto.terms,
                        terms_text: dto.terms_text,
                        terms_url: dto.terms_url
                    },
                    q: { id: appid }
                });

               

                notifyManager.info("App update success");


            } catch (error) {
                notifyManager.exception(error)
            }

        }
    });
    return <div className="box">
        <AppEditTab appid={appid} active="settings" />
        <form onSubmit={frm.submiter} >

            {/* {JSON.stringify(dto)} */}

            <InputYesNo label="Бүртгэл нийтэд боломжтой байх эсэх" value={dto.public_register} onChange={e => setDto({ ...dto, public_register: e })} disabled={frm.loading} />

            <div className="box">
                <InputString label="Үйлчилгээний нөхцөл" value={dto.terms} onChange={e => setDto({ ...dto, terms: e })} disabled={frm.loading} />
                <InputString label="Үйлчилгээний нөхцөл. url" value={dto.terms_url} onChange={e => setDto({ ...dto, terms_url: e })} disabled={frm.loading || dto.terms ? false : true} />
                <InputText label="Үйлчилгээний нөхцөл. text" value={dto.terms_text} onChange={e => setDto({ ...dto, terms_text: e })} disabled={frm.loading || dto.terms ? false : true} />
            </div>

            <div className="box">
                <InputString label="Нууцлалын бодолго" value={dto.policy} onChange={e => setDto({ ...dto, policy: e })} disabled={frm.loading} />
                <InputString label="Нууцлалын бодолго. url" value={dto.policy_url} onChange={e => setDto({ ...dto, policy_url: e })} disabled={frm.loading || dto.policy ? false : true} />
                <InputText label="Нууцлалын бодолго. text" value={dto.policy_text} onChange={e => setDto({ ...dto, policy_text: e })} disabled={frm.loading || dto.policy ? false : true} />
            </div>



            <div className="columns">
                <div className="column">
                    <div className="buttons">

                        <button type="submit" className="button is-primary" disabled={frm.loading}>
                            <span>Submit</span>
                        </button>

                        <button type="button" className="button" disabled={frm.loading} onClick={() => history.push(`/app/${appid}`)}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>

            </div>

        </form>

    </div>
}