import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "src/comm/hook.form";
import { InputString } from "src/comps/form/input.string";
import { InputText } from "src/comps/form/input.text";
import { Dtiapp_appUpdate } from "src/gen/dti/appconsole/app.update";
import { Dtiapp_appGet } from "src/gen/dti/appconsole/app.get";
import { useAppManager } from "src/manager";
import { Link } from "react-router-dom";
import { AppEditTab } from "./edit.tab";

interface IProps {
    appid: string;
    onChange: () => void
}
export function AppEdit({ appid, onChange }: IProps) {
    const { apiAppconsole, notifyManager } = useAppManager();
    const history = useHistory();

    const [dto, setDto] = useState({
        name: '', domain: '', desc: ''
    });

    useEffect(() => {
        if (appid) {
            (async () => {
                let { name, domain, desc } = await apiAppconsole.dti(Dtiapp_appGet.meta).call({
                    q: { id: appid }
                });
                setDto({ name, domain, desc })
            })();
        }
        return () => { }
    }, [appid])

    const frm = useForm({

        submit: async () => {
            try {
                // console.log('dto', dto)
                let { flag } = await apiAppconsole.dti(Dtiapp_appUpdate.meta).call({
                    b: { name: dto.name, desc: dto.desc },
                    q: { id: appid }
                });

                if (flag) {
                    onChange()
                }

                notifyManager.info("App update success");


            } catch (error) {
                notifyManager.exception(error)
            }

        }
    });
    return <div className="box">
        <AppEditTab appid={appid} active="edit" />
        <form onSubmit={frm.submiter} >
            <InputString label="domain" value={dto.domain} disabled={true} />
            <InputString label="name" value={dto.name} onChange={e => setDto({ ...dto, name: e })} disabled={frm.loading} />
            <InputText label="desc" value={dto.desc} onChange={e => setDto({ ...dto, desc: e })} disabled={frm.loading} />

            <div className="columns">
                <div className="column">
                    <div className="buttons">

                        <button type="submit" className="button is-primary" disabled={frm.loading}>
                            <span>Submit</span>
                        </button>

                        <button type="button" className="button" disabled={frm.loading} onClick={() => history.push(`/app/${appid}`)}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
                <div className="column has-text-right">
                    <Link to={`/app/${appid}/delete`} className="button is-danger">
                        <span className="icon"><i className="fas fa-trash" /></span>
                        <span>Устгах</span>
                    </Link>
                </div>
            </div>

        </form>
    </div>
}