import { Dti } from "@napp/dti-core";

export namespace Dtiapp_accessInvite {
    export interface BParam {
        identity: string;
        appid: string;
        roles: string[]
    }



    export interface Resu {
        url: string;
    }


    export const meta = Dti.define<Resu, void, BParam>({
        name: 'app-access-invite', method: 'post',
        checkB: (b) => {
            if ((b.identity || '') === '') {
                throw new Error('identity is requared')
            }
            if ((b.appid || '') === '') {
                throw new Error('appid is requared')
            }
            if (Array.isArray(b.roles) === false) {
                throw new Error('roles is requared')
            }

        }
    });
}