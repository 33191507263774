import { useCallback, useEffect, useRef, useState } from "react";

export function useAsync<R extends object>(action: () => Promise<R>, lazy?: boolean) {
    const [error, setError] = useState<any>();
    const [result, setResult] = useState<R>();
    const [loading, setLoading] = useState<boolean>();

    const cancelled = useRef(false);

    const handle = useCallback(() => {
        setResult(undefined)
        setError(undefined)
        setLoading(true)
        action()
            .then((r) => {
                if (cancelled.current === false) {
                    if (r) {
                        setResult(r);
                    } else {
                        throw new Error("not supoered result. in useAsync")
                    }
                }
            })
            .catch(error => {
                console.log(error)
                if (cancelled.current === false) {
                    setError(error);
                }
            })
            .finally(() => {
                if (cancelled.current === false) {
                    setLoading(false);
                }
            })
            ;
    }, [action]);

    useEffect(() => {

        if ((lazy || false) === false) {
            handle();
        }
        return () => {
            cancelled.current = true
        };
    }, []);





    return {
        loading,
        error,
        result,
        handle
    }
}
