export enum ROLEAppconsole {
    AppEdit = 'appedit',
    AppEditSettings = 'appeditSettings',
    AppDelete = 'appdelete',
    AccessInvite = 'accessInvite',
    AccessEdit = 'accessEdit',
    AccessDelete = 'accessDelete',
}

export const ROLEAppconsoleList = [
    ROLEAppconsole.AppEdit,
    ROLEAppconsole.AppEditSettings,
    ROLEAppconsole.AppDelete,
    ROLEAppconsole.AccessInvite,
    ROLEAppconsole.AccessEdit,
    ROLEAppconsole.AccessDelete,
]

export const ROLEAppconsoleDesc = {
    [ROLEAppconsole.AppEdit]: 'Апп засах. нэр, тайлбар зэрэг талбаруудын',
    [ROLEAppconsole.AppEditSettings]: 'Апп тохируулга. нээлттэй бүртгэл, үйлчилгээний нөхцөл, нууцлалийн бодлого гэх зэрэг',
    [ROLEAppconsole.AppDelete]: 'Апп устгах',
    [ROLEAppconsole.AccessInvite]: 'Админ хэрэглэгч урих (нэмэх) боломж',
    [ROLEAppconsole.AccessEdit]: 'Админ хэрэглэгчийн эрхийг өөрчлөх(role change) боломж',
    [ROLEAppconsole.AccessDelete]: 'Админ хэрэглэгчийн устгах боломж',
}

