import { ReactNode, PropsWithChildren } from "react";

interface IProps {
    label: string;
    icon?: string;
    name?: string;

    className?: string;
    value?: string;
    onChange?: (val: string) => void;

    required?: ReactNode;
    maxLength?: number;
    minLength?: number;

    placeholder?: string;

    readonly?: boolean;
    disabled?: boolean;
    loading?: boolean;

    size?: 'small' | 'medium' | 'large';
    status?: 'success' | 'warning'

}
export function InputString({ className, label, icon, name, onChange, value, required, minLength, maxLength, placeholder, disabled, readonly, loading, size, status, children }: PropsWithChildren<IProps>) {
    return <div className={`field ${className ?? ''}`}>
        <label className="label">{label} {required ? (typeof required === 'boolean' ? '*' : required) : ''}</label>
        <div className={`control ${icon ? 'has-icons-left' : ''} ${loading ? 'is-loading' : ''} ${size ? 'is-' + size : ''}`}>
            <input className={`input ${status ? 'is-' + status : ''}`} name={name} type="text" required={required ? true : undefined} readOnly={readonly}
                minLength={minLength} maxLength={maxLength} placeholder={placeholder} disabled={disabled || loading}
                value={value} onChange={e => onChange && onChange(e.target.value)} />
            {icon
                ? <span className="icon is-small is-left">
                    <i className="fas fa-lock"></i>
                </span>
                : null
            }
        </div>
        {children}
    </div>
}