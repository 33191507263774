import React, { Fragment } from "react";

import { Subscription } from 'rxjs';
import { ModalManager } from "./manager";
import { Modaler } from "./modaler";
import "./style.scss";

interface IBasicProps {
    mdl?: Modaler;
}


export class ModelBasicComponent extends React.Component<IBasicProps>{

    render() {
        let { children, mdl } = this.props;
        return <div className="modal is-active">
            <div className="modal-background" onClick={() => mdl && mdl.close({ result: 'cancel' })} />
            <div className="modal-content">
                {children}
            </div>
            {mdl
                ? <button className="modal-close is-large" aria-label="close" onClick={() => mdl && mdl.close({ result: 'cancel' })}></button>
                : null
            }
        </div>
    }
}

interface ICardProps {
    mdl?: Modaler;

    large?: boolean;

    header?: React.ReactNode;
    headerRender?: () => React.ReactNode;
    footer?: React.ReactNode
}
interface ICardState {

}

export class ModelCardComponent extends React.Component<ICardProps, ICardState>{

    renderHeader() {
        let { mdl, header, headerRender } = this.props;
        if (headerRender) {
            return <header className="modal-card-head">
                {headerRender()}
            </header>
        }

        if (header) {
            return <header className="modal-card-head">
                <p className="modal-card-title">{header}</p>
                {mdl
                    ? <button className="delete" aria-label="close" onClick={() => mdl && mdl.close({ result: 'cancel' })}></button>
                    : null
                }
            </header>
        }

        return null;
    }
    renderFooter() {
        let { footer } = this.props;

        if (footer) {
            return <header className="modal-card-foot">
                {footer}
            </header>
        }

        return null;
    }
    render() {
        let { children, large } = this.props;
        return <div className="modal is-active">
            <div className="modal-background"></div>
            <div className={`modal-card ${large ? 'is-large' : ''}`}>
                {this.renderHeader()}
                <section className="modal-card-body">
                    {children}
                </section>
                {this.renderFooter()}
            </div>
        </div>
    }
}

export class ModalContainer extends React.Component<{ manager: ModalManager }> {

    cleaner = new Subscription()
    componentDidMount() {
        let { manager } = this.props;
        let un = manager.OnChange.subscribe(() => {
            this.forceUpdate();
        });

        this.cleaner.add(un)
    }

    componentWillUnmount() {
        this.cleaner.unsubscribe();
    }

    render() {
        let { manager } = this.props;
        return <>
            {manager.items.map(it => {
                return <Fragment key={it.id}>{it.render(it)}</Fragment>
            })}
        </>
    }
}